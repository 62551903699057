import * as React from 'react';
import { FC, useContext } from 'react';
import {
  Box,
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  StackProps,
  Tag,
  TagLabel,
  TagLeftIcon
} from '@chakra-ui/react';
import { ulid } from 'ulid';
import { IoCartSharp } from 'react-icons/io5';
import { ChevronDownIcon } from '@chakra-ui/icons';
import NextLink from 'next/link';
import { NavLink } from './NavLink';
import { ShopDispatchContext, ShopStateContext } from '../../contexts/ShopContext';
import { AuthStateContext } from '../../contexts/AuthContext';
import { CartCount } from './CartCount';

const MobileNavContent = ({ callback }) => {
  const { isAuthenticated } = useContext(AuthStateContext);
  const { dispatch } = useContext(ShopDispatchContext);
  const { profiles } = useContext(ShopStateContext);

  let links = [
    { label: 'Sign in', href: '/sign-in/' },
    { label: 'Sign up', href: '/sign-up/' }
  ];

  if (isAuthenticated) {
    links = [
      { label: 'Account', href: '/account/' },
      { label: 'Sign out', href: '/sign-out/' }
    ];
  }

  return (
    <>
      {/* Anonymous */}
      {!isAuthenticated &&
        links.map((link) => (
          <Button as={NextLink} href={link.href} key={link.label} size='lg' variant='text' colorScheme='gray'>
            {link.label}
          </Button>
        ))}

      {/* Cart */}
      <Button
        variant='text'
        size='lg'
        colorScheme='gray'
        onClick={() => {
          callback();
          dispatch({ type: 'toggleCartOpen' });
        }}
      >
        Cart{' '}
        {profiles?.length > 0 && (
          <Tag size='lg' variant='subtle' colorScheme='brand' ml={2}>
            <TagLeftIcon boxSize='20px' as={IoCartSharp} />
            <TagLabel>{profiles.length}</TagLabel>
          </Tag>
        )}
      </Button>

      {/* Quiz */}
      <Button
        as={NextLink}
        href='/quiz/'
        size='lg'
        colorScheme={isAuthenticated ? 'gray' : 'brand'}
        variant={isAuthenticated ? 'text' : 'solid'}
      >
        Take the Quiz
      </Button>

      {/* Authenticated */}
      {isAuthenticated &&
        links.map((link) => (
          <Button as={NextLink} href={link.href} key={link.label} size='lg' variant='text' colorScheme='gray'>
            {link.label}
          </Button>
        ))}
    </>
  );
};

const DesktopNavContent: FC<StackProps> = (props) => {
  const { profiles } = useContext(ShopStateContext);
  const { dispatch } = useContext(ShopDispatchContext);
  const { isAuthenticated, isAdmin } = useContext(AuthStateContext);
  let links = [
    { label: 'Sign up', href: '/sign-up/' },
    { label: 'Sign in', href: '/sign-in/' }
  ];

  if (isAuthenticated) {
    links = [
      { label: 'Account', href: '/account/' },
      { label: 'Sign out', href: '/sign-out/' }
    ];
  }

  return (
    <HStack spacing='8' align-items='center' {...props}>
      {!isAuthenticated &&
        links.map((link) => (
          <NavLink.Desktop href={link.href} key={link.label} _dark={{ color: 'white' }}>
            {link.label}
          </NavLink.Desktop>
        ))}
      <Box cursor='pointer' onClick={() => dispatch({ type: 'toggleCartOpen' })}>
        <Box position='relative'>
          <Icon fill='gray.800' _dark={{ fill: 'white' }} as={IoCartSharp} w='4' h='4' pt='2' boxSize='2em' />
          {profiles && profiles.length > 0 && <CartCount _dark={{ color: 'brand.700' }}>{profiles.length}</CartCount>}
        </Box>
      </Box>
      <Button as={NextLink} href='/quiz/' colorScheme='brand' _dark={{ bgColor: 'brand.300', color: 'brand.700' }}>
        Take the Quiz
      </Button>
      Admin menu
      {isAuthenticated && isAdmin && (
        <Menu>
          <MenuButton as={Button} colorScheme='brand' rightIcon={<ChevronDownIcon />}>
            Admin
          </MenuButton>
          <MenuList alignContent='start'>
            <MenuItem minH='48px' display='block'>
              <NavLink.Desktop href='/admin/users' _hover={{ textDecoration: 'none' }} textAlign='left'>
                Find user
              </NavLink.Desktop>
            </MenuItem>
            <MenuItem minH='48px' display='block'>
              <NavLink.Desktop href='/admin/cancel-subscription' _hover={{ textDecoration: 'none' }} textAlign='left'>
                Cancel subscription
              </NavLink.Desktop>
            </MenuItem>
          </MenuList>
        </Menu>
      )}
      {isAuthenticated &&
        links.map((link) => (
          <NavLink.Desktop href={link.href} key={ulid()}>
            {link.label}
          </NavLink.Desktop>
        ))}
    </HStack>
  );
};

export const NavContent = {
  Mobile: MobileNavContent,
  Desktop: DesktopNavContent
};

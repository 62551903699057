import {
  Box,
  Container as ChakraContainer,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerProps,
  Flex,
  HStack,
  LightMode,
  Show,
  Stack,
  useDisclosure,
  VisuallyHidden
} from '@chakra-ui/react';
import React, { FC } from 'react';
import NextLink from 'next/link';
import { Logo } from './Logo';
import { NavContent } from './NavContent';
import { ToggleButton } from './NavbarToggleButton';

interface NavbarProps {
  darkBackground?: boolean;
}

export const MobileDrawer = ({ onClose, ...props }: Omit<DrawerProps, 'children'>) => (
  <Drawer placement='top' onClose={onClose} {...props}>
    <DrawerContent>
      <DrawerBody mt='16'>
        <Stack spacing='6' align='stretch'>
          <NavContent.Mobile callback={onClose} />
        </Stack>
      </DrawerBody>
    </DrawerContent>
  </Drawer>
);

export const Navbar: FC<NavbarProps> = (props) => {
  const mobileNavbar = useDisclosure();
  return (
    <Box>
      {/* Desktop */}
      <Show above='md'>
        <Box as='header' height='16' position='relative' zIndex='sticky'>
          <Box height='100%' maxW='7xl' mx='auto' ps={{ base: '6', md: '8' }} pe={{ base: '5', md: '0' }}>
            <Flex as='nav' aria-label='Site navigation' align='center' justify='space-between' height='100%'>
              <Box as={NextLink} href='/' rel='home'>
                <VisuallyHidden>Vitamin Club</VisuallyHidden>
                <Logo h='8' />
              </Box>
              <NavContent.Desktop {...props} display={{ base: 'none', md: 'flex' }} />
            </Flex>
          </Box>
        </Box>
      </Show>
      {/* Mobile */}
      <Show below='md'>
        <Box as='section' _dark={{ bgColor: 'whiteAlpha.500' }}>
          <Box borderBottomWidth='1px' _dark={{ borderBottom: 'none' }} position='relative' zIndex='tooltip'>
            <ChakraContainer py='4'>
              <HStack justify='space-between'>
                <Box as={NextLink} href='/' rel='home'>
                  <VisuallyHidden>Vitamin Club</VisuallyHidden>
                  <Logo h='8' />
                </Box>
                <ToggleButton onClick={mobileNavbar.onToggle} isOpen={mobileNavbar.isOpen} aria-label='Open Menu' />
                <LightMode>
                  <MobileDrawer isOpen={mobileNavbar.isOpen} onClose={mobileNavbar.onClose} />
                </LightMode>
              </HStack>
            </ChakraContainer>
          </Box>
        </Box>
      </Show>
    </Box>
  );
};

import { Box } from '@chakra-ui/react';
import * as React from 'react';
import { FaFacebook, FaHeart, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

export const Heart = () => (
  <Box display='inline-block' mx='1' color='orange.500' fontSize='xs' role='img' aria-label='Love' as={FaHeart} />
);

export interface LinkGroupData {
  title: string;
  links: Array<{
    label: string;
    href: string;
    badge?: React.ReactElement;
  }>;
}

export const links: LinkGroupData[] = [
  {
    title: 'Product',
    links: [
      { label: 'How it works', href: '/#how-it-works' },
      { label: 'Why Vitamin Club', href: '/#why' },
      { label: 'Quality promise', href: '/#quality-promise' },
      { label: 'Testimonials', href: '/#testimonials' },
      { label: 'Pricing', href: '/#pricing' }
    ]
  },
  {
    title: 'Resources',
    links: [
      { label: 'FAQs', href: '/frequently-asked-questions/' },
      { label: 'Support', href: 'mailto:support@vitaminclub.co.za' },
      { label: 'Terms of Service', href: '/terms/' },
      { label: 'Privacy Policy', href: '/privacy/' }
    ]
  },
  {
    title: 'About',
    links: [
      { label: 'Our Story', href: '/our-story/' },
      { label: 'Media Kit', href: '/media-kit/' },
      { label: 'Email us', href: 'mailto:info@vitaminclub.co.za' }
    ]
  },
  {
    title: 'Get Started',
    links: [
      {
        label: 'Take the quiz',
        href: '/quiz/',
        badge: <Heart />
      },
      { label: 'Sign in', href: '/sign-in/' }
    ]
  }
];

interface SocialLink {
  label: string;
  icon: React.ReactElement;
  href: string;
}

export const socialLinks: SocialLink[] = [
  { label: 'Facebook', icon: <FaFacebook />, href: 'https://web.facebook.com/vitaminclubza' },
  { label: 'Instagram', icon: <FaInstagram />, href: 'https://www.instagram.com/vitaminclubza' },
  { label: 'Twitter', icon: <FaXTwitter />, href: 'https://twitter.com/vitaminclubza' }
];

interface FooterLink {
  label: string;
  href: string;
}

export const footerLinks: FooterLink[] = [
  { label: 'Terms of Service', href: '#' },
  { label: 'Privacy Policy', href: '#' },
  { label: 'Offer terms', href: '#' },
  { label: 'Legal notice', href: '#' },
  { label: 'Sitemap', href: '#' }
];

import React from 'react';
import NextHead from 'next/head';

interface HeadProps {
  title?: string;
  titlePostfix?: string;
}

const Head: React.FC<HeadProps> = ({ title, titlePostfix }) => (
  <NextHead>
    <meta charSet='utf-8' />
    <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png?v=1' />
    {/* To run web application in full-screen */}
    <meta name='apple-mobile-web-app-capable' content='yes' />
    <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png?v=1' />
    <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png?v=1' />
    <link rel='manifest' href='/site.webmanifest?v=1' />
    <link rel='mask-icon' href='/safari-pinned-tab.svg?v=1' color='#fe7820' />
    <link rel='shortcut icon' href='/favicon.ico?v=1' />
    <meta name='msapplication-TileColor' content='#fe7820' />
    <meta name='theme-color' content='#ffffff' />

    <meta name='viewport' content='width=device-width, initial-scale=1, viewport-fit=cover' />
    <meta name='description' content='Your Vitamins, your way' />

    <title>{title || (titlePostfix ? `${titlePostfix} | Vitamin Club` : 'Vitamin Club')}</title>
  </NextHead>
);

export default Head;

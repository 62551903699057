import { Box, Flex, HStack, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import * as React from 'react';
import { Logo } from './Logo';
import { SocialLink } from './SocialLink';
import { links, socialLinks } from './_data';
import { LinkGroup } from './LinkGroup';

export const FooterWithNewsletter = () => (
  <Box as='footer' bg='gray.200'>
    <Box maxW={{ base: 'xl', md: '7xl' }} mx='auto' px={{ base: '6', md: '8' }} py={{ base: '12', md: '20' }}>
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        justify='space-between'
        mb={{ base: '10', lg: '16' }}
        align='flex-start'
        id='top'
      >
        <SimpleGrid
          flex='1'
          w={{ base: 'full', lg: 'auto' }}
          maxW={{ lg: '2xl' }}
          columns={{ base: 1, md: 2, lg: 4 }}
          spacing={{ base: '12', md: '10' }}
          fontSize='sm'
          marginEnd={{ md: '4', lg: '16' }}
        >
          {links.map((group, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <LinkGroup key={idx} data={group} />
          ))}
        </SimpleGrid>
      </Flex>

      <Flex
        direction={{ base: 'column', lg: 'row' }}
        align={{ base: 'flex-start', lg: 'center' }}
        justify='space-between'
        fontSize='sm'
      >
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '4', md: '12' }}
          mt={{ base: '8', lg: 0 }}
          w={{ base: 'full', lg: 'auto' }}
          justify={{ base: 'space-between', lg: 'flex-start' }}
          align={{ base: 'flex-start', md: 'center' }}
        >
          <Logo h='6' />
          <HStack spacing='2' as='ul' listStyleType='none'>
            {socialLinks.map((link) => (
              <Box as='li' key={link.label}>
                <SocialLink href={link.href} color='gray.600'>
                  <Box srOnly>{link.label}</Box>
                  {link.icon}
                </SocialLink>
              </Box>
            ))}
          </HStack>
        </Stack>
        <Stack
          direction={{ base: 'column-reverse', md: 'row' }}
          spacing={{ base: '4', md: '12' }}
          mt={{ base: '8', lg: 0 }}
          w={{ base: 'full', lg: 'auto' }}
          justify={{ base: 'space-between', lg: 'flex-start' }}
          align={{ base: 'flex-start', md: 'center' }}
        >
          <Box>
            <Text>&copy; {new Date().getFullYear()} Vitamin Me (Pty) Ltd</Text>
          </Box>
          <HStack spacing={2}>
            <Text>South Africa</Text>
            <Image src='/images/za@2x.png' boxSize='24px' mr={10} loading='lazy' alt='ZA flag' />
          </HStack>
        </Stack>
      </Flex>
    </Box>
  </Box>
);

import React from 'react';
import { Box, Stack, Text, Link } from '@chakra-ui/react';
import NextLink from 'next/link';
import { LinkGroupData } from './_data';

interface LinkGroupProps {
  data: LinkGroupData;
}

export const LinkGroup = (props: LinkGroupProps) => {
  const { data } = props;
  const { links, title } = data;

  return (
    <Box>
      <Text textTransform='uppercase' mb={{ base: '6', md: '10' }} fontWeight='bold' letterSpacing='wide'>
        {title}
      </Text>
      <Stack as='ul' spacing={{ base: 2, md: 4 }} listStyleType='none'>
        {links.map((link) => (
          <Box as='li' key={link.href}>
            <Link as={NextLink} href={link.href} color='gray.600' _hover={{ textDecoration: 'underline' }}>
              <span>{link.label}</span>
              {link.badge && (
                <Box as='span' ms='2'>
                  {link.badge}
                </Box>
              )}
            </Link>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

import * as React from 'react';
import { Link, useColorModeValue } from '@chakra-ui/react';
import NextLink from 'next/link';
import { NextChakraLinkProps } from './Link';

const DesktopNavLink = ({ href, ...props }: NextChakraLinkProps) => (
  <Link
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    as={NextLink}
    href={href}
    color={useColorModeValue('gray.800', 'gray.100')}
    fontWeight='medium'
    borderBottom='2px'
    borderColor='transparent'
    transition='all 0.2s'
    _hover={{
      borderColor: 'currentColor',
      color: useColorModeValue('orange.500', 'gray.200')
    }}
    {...props}
  />
);

const MobileNavLink = (props: NextChakraLinkProps) => (
  <Link
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    as={NextLink}
    py='5'
    fontSize='lg'
    color='white'
    w='full'
    _hover={{ bg: 'blackAlpha.200' }}
    {...props}
  />
);

export const NavLink = {
  Mobile: MobileNavLink,
  Desktop: DesktopNavLink
};

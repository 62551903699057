import * as React from 'react';
import { Circle, SquareProps, useColorModeValue as mode } from '@chakra-ui/react';

export const CartCount = ({ children, ...props }: SquareProps) => (
  <Circle
    fontSize='xs'
    fontWeight='semibold'
    centerContent
    position='absolute'
    top='0'
    right='-4'
    bg={mode('brand.500', 'brand.300')}
    color={mode('white', 'black')}
    width='5'
    height='5'
    {...props}
  >
    {children}
  </Circle>
);
